.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.App {
  text-align: center;
  padding-top: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  display: block;
  z-index: 100;
  background: #000;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 1px;
  max-width: 960px;
}

.modal .carousel .slide .legend {
  bottom: 10px;
  font-size: 1.1rem;
  opacity: .9;
  width: 90%;
}

.modal .carousel .control-dots {
  background: #000;
  bottom: -15px;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  opacity: .95;
  cursor: pointer;
  border: none;
}

button {
  font-weight: 700;
  border: none;
  padding: .3rem 1rem;
  margin-left: .5rem;
}

.button-default {
  background: #3298dc;
  color: #fff;
}