// 2. Setup your Custom Colors
$purple: hsl(275,87%,45%);
$purple-invert: findColorInvert($purple);
// 3. Add new color variables to the color map.

// @import '../../bulma/sass/utilities/_all.sass';
@import "~bulma/sass/utilities/_all"; 

// @import "bulma/sass/utilities/derived-variables.sass";
$addColors: (
  "purple":($purple, $purple-invert),
);
$colors: map-merge($colors, $addColors);
@import 'bulma/bulma';
// @import 'bulma-extensions/extensions';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hero-body {
  padding: 1.5rem;
}

#map > div {
  position: fixed !important; 
  height: 100% !important;
  width: 100% !important;
  max-width: 980px;
}

.hidden {
  display: none;
}

.carousel .slide div {
  max-height: 700px;
}

hr {
  margin: .5rem 0;
}

.box {
  margin-bottom: 1rem;
}

.social-icon {
  height: 35px !important;
  width: 35px !important;
  margin-right: 10px;
  margin-top: 5px;
}

.blog {
  max-width: 960px;
  margin: 0 auto;

  .content {
    h3 {
      margin-bottom: 1em;
      margin-top: 0;
    }
  
    .blog-content {
      max-width: 760px;
      text-align: justify;
    
      ul {
        margin-left: 1.2em;
      }

      img {
        float: right;
        margin-left: 1.5rem;
      }
    }
  
    .blog-image {
      float: right;
      margin-top: 0;
      margin-right: 0;
      max-width: 600px;
    }
  }
}

.cv {
  .field-body {
    text-align: left;
  }
}

.footer {
  padding: 1.5rem 1.5rem .5rem;
  text-align: center;

  a {
    display: inline-block;
  }
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px) {
    .blog .content .blog-content img {
      clear: both;
      text-align: left;
      margin-left: 0;
      padding: 1rem;
    }
}